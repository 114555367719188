<template>
<div id="EditAward">

<!-- notification -->
<v-snackbar v-model="notificationToggle" :color="notificationType" :timeout="5000" :top="true"> {{ notificationMsg }}
  <v-btn dark text @click="notificationToggle = false" > Close </v-btn>
</v-snackbar>

<!-- edit form -->
<v-form @submit.prevent="UpdateAward">
<v-container class="pa-5">
<v-card class="pa-2 mt-5 elevation-5">
  <v-card-title>
    <h3>Edit Award</h3>
  </v-card-title>
  <v-card-text class="pa-2">
    <v-row>
      <!-- add form -->
      <v-col cols="12" sm="12" md="12">
          <v-text-field label="Title" :error="$v.Title.$error" :error-messages="TitleErrors"
          v-model.trim="Title" @input="$v.Title.$touch()" required></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="12">
          <v-textarea label="Description"  :error="$v.Description.$error" :error-messages="DescriptionErrors"
          @input="$v.Description.$touch()" v-model="Description" required></v-textarea>
      </v-col>
    </v-row>
  </v-card-text>
  <v-card-actions class="pa-3">
      <v-btn type="submit" class="success elevation-10" :disabled="disbtn">Update Award</v-btn>
      <v-btn @click="$router.go(-1)" class="secondary elevation-10">back</v-btn>
  </v-card-actions>

  <!-- show overlay when add item -->
  <v-fade-transition>
  <v-overlay absolute :value="disbtn">
    <v-progress-circular color="yellow" indeterminate width="15" size="100"></v-progress-circular>
  </v-overlay>
  </v-fade-transition>
  
</v-card>
</v-container>
</v-form>

</div>
</template>

<script>
// imports
import { required } from 'vuelidate/lib/validators';
import firebase from 'firebase/app';
import "firebase/firestore";
import "firebase/storage";

// scripts
export default {

    name: 'EditAward',

    created(){this.$store.dispatch('getAwards');},

    mounted(){
      this.AwardData != null ? this.assignData(this.AwardData) : null;
    },

    data(){return{
        
        // app flags
        notificationToggle: false,notificationMsg: 'hai',notificationType: 'hui',disbtn: false,

        // student flags
        Title: '',Description: '',selectedAwardFromLoop: [],
    }},

    methods:{

      // add post
      async UpdateAward(){
        // vars
        const dateObj = new Date();
        // blank validation
        if(!this.BlankValidation()){return false;}this.disbtn = true;
        // create a unique slug
        let awardslug = this.Slugify(this.Title) + dateObj.getMilliseconds() + '-kalalaya-academy-' +
                        Math.floor(Math.random() * 107477);  + dateObj.getSeconds().toString();

        // actual update statement
        // get document where student id equals selected one
        await firebase.firestore().collection("awards").where("awardslug", "==", this.selectedAwardFromLoop.awardslug).get().then(
         (succ) => {
            // update all datas(sorry for cramping it up)
            firebase.firestore().collection("awards").doc(succ.docs[0].id).update({
                "awardslug": awardslug,"title": this.Title,"description": this.Description,
            }).then((res) => {

                // update award slug as well on student
                firebase.firestore().collection("studentAwards").where("awardslug", "==", this.selectedAwardFromLoop.awardslug).get().then(
                (doc) => {
                  if(doc.docs.length != 0){
                    // Once we get the results, begin a batch
                    var batch = firebase.firestore().batch();
                    doc.forEach(function(q) {
                        // For each doc, add a update operation to the batch
                        batch.update(q.ref, {"awardslug": awardslug});
                    });
                    // Commit the batch
                    batch.commit();
                  }
                });

                this.ResetFields();this.notificationService("Successfully Updated!", "success");
                setTimeout(()=>{ this.$router.push('/awards'); }, 1000);this.$store.dispatch('getAwards');
                this.disbtn = true;

            });
          
         }, (err) => { console.log(err);  }); 

      },

       // assign curresponsing data to student
      assignData(selectedData){
        if(selectedData.length > 0){
            selectedData.forEach((sd) => {
            this.Title = sd.title, this.Description = sd.description,
            this.selectedAwardFromLoop = sd;
        });}else{return null}
      },

      // reset fields
      ResetFields(){     
        this.Title = '';this.Description = '';
        this.$v.$reset();
      },

      // check if all fileds are empty   
      BlankValidation(){
        if(this.Title == '' || this.Description == '')
        {this.notificationService("Fill All Fields!", "red darken-1"); return false;}else{return true;}
      },
      
      // error pusher for vuelidate   
      ErrorPusher(basic, extra, msg){
        const errors = []
        if (!basic) return errors
        !extra && errors.push(msg);
        return errors;
      },

      // notification service
      notificationService(msg, type){
        this.notificationType = type;
        this.notificationMsg = msg;
        this.notificationToggle = true;
      },

      // slugify title (copied from medium :-D)
      Slugify(string) {
        const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
        const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
        const p = new RegExp(a.split('').join('|'), 'g')

        return string.toString().toLowerCase()
            .replace(/\s+/g, '-') // Replace spaces with -
            .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
            .replace(/&/g, '-and-') // Replace & with 'and'
            .replace(/[^\w\-]+/g, '') // Remove all non-word characters
            .replace(/\-\-+/g, '-') // Replace multiple - with single -
            .replace(/^-+/, '') // Trim - from start of text
            .replace(/-+$/, '') // Trim - from end of text
      },

    },

     // validations
    validations: {
        Title: { required }, 
        Description: { required },
    },

    computed: {

      AwardSlug(){         
        return this.$route.params.awardslug != null ? this.$route.params.awardslug : null;
      },

      AwardData(){
        if(this.AwardSlug != null) {
            let data =  this.$store.getters.GetAwardWithSlug(this.AwardSlug);
            return data.length != 0 ? data : this.$router.push("/awards");
        }else{ return this.$router.push("/awards"); }             
      },
  
      // basically in computed, i added validations  
      // sorry, i cant find a way to make this even smaller : -D
      TitleErrors () { return this.ErrorPusher(this.$v.Title.$dirty, this.$v.Title.required, 'Title is Required'); },
      DescriptionErrors () { return this.ErrorPusher(this.$v.Description.$dirty, this.$v.Description.required, 'Description is Required'); },
      
    }
}
</script>
